import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Form from './Form';

const Role: React.FC = () => {
  return (
    <Switch>
      <Route path="/roles" component={List} isPrivate exact />
      <Route path="/roles/new" component={Form} isPrivate />
      <Route path="/roles/edit/:id" component={Form} isPrivate />
    </Switch>
  );
};

export default Role;
