import React, {
  InputHTMLAttributes,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { FieldError, Controller, Control } from 'react-hook-form';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { MdCancel } from 'react-icons/md';
import { debounce } from 'lodash';
import api from '../../services/api';
import * as S from './styles';

type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined;

export type Option = {
  id: number | string;
  name: string;
};

interface AutoCompleteMultiProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: object;
  icon?: React.ComponentType<IconBaseProps>;
  register: RefReturn;
  errors: FieldError | undefined;
  control: Control;
  url: string;
  per_page?: number;
  delay?: number;
  defaultOption?: Option[];
  labelValue?: string;
  keyValue?: string;
}

const AutoCompleteMulti: React.FC<AutoCompleteMultiProps> = ({
  name,
  register,
  errors,
  control,
  containerStyle = {},
  icon: Icon,
  url,
  per_page = 10,
  delay = 500,
  defaultOption = [],
}) => {
  const inputRefVisible = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([] as Option[]);
  const [optionsSelected, setOptionsSelected] = useState<Option[]>([]);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const delaySearch = useRef(
    debounce((value: string) => {
      setLoading(true);
      api
        .get(url, {
          params: {
            page: 1,
            per_page,
            term: value,
          },
        })
        .then((response) => {
          const { data } = response;
          setOptions([...options, ...data]);
        })
        .finally(() => {
          setLoading(false);
        });
    }, delay),
  ).current;

  const handleSearch = (e: React.FormEvent<HTMLInputElement>): void => {
    delaySearch(e.currentTarget.value);
  };
  const handleRemove = (item: Option): void => {
    setOptionsSelected(
      optionsSelected?.filter((option) => option.id !== item.id),
    );
  };

  const handleClear = (): void => {
    if (inputRefVisible.current) {
      inputRefVisible.current.value = '';
      inputRefVisible.current?.focus();
    }
    setOptions([]);
  };

  const handleItemSelected = useCallback(
    (itemSelected: Option): void => {
      const opts = [...optionsSelected, itemSelected];
      if (inputRefVisible.current) {
        inputRefVisible.current.value = '';
        inputRefVisible.current.focus();
        setOptionsSelected(opts);
      }
      if (opts.length) {
        opts.map((item) => item.id).join(',');
        /*  console.log('current', inputRefHidden.current.value); */
      }
      setOptions([]);
    },
    [inputRefVisible, optionsSelected],
  );

  useEffect(() => {
    setOptionsSelected([...optionsSelected, ...defaultOption]);
    // eslint-disable-next-line
  }, [defaultOption]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={optionsSelected.map((item: Option) => item.id).join(',')}
      render={() => (
        <>
          <S.Container
            style={containerStyle}
            isFocused={isFocused}
            isFilled={false}
            isErrored={!!errors}
          >
            <input
              name={name}
              hidden
              ref={register}
              value={optionsSelected.map((item: Option) => item.id).join(',')}
              onChange={handleSearch}
            />
            {Icon && <Icon size={20} />}

            <S.ItemSelectedList>
              {optionsSelected?.map((option: Option) => (
                <S.ItemSelectedContainer key={String(option.id)}>
                  <S.ItemSelected>{option.name}</S.ItemSelected>
                  <S.IconRemoveItemSelected
                    onClick={() => handleRemove(option)}
                  >
                    <MdCancel size={16} color="var(--primary)" />
                  </S.IconRemoveItemSelected>
                </S.ItemSelectedContainer>
              ))}
            </S.ItemSelectedList>

            <input
              ref={inputRefVisible}
              onFocus={(e) => {
                setIsFocused(true);
                e.target.select();
              }}
              onBlur={handleInputBlur}
              onChange={handleSearch}
            />
            {errors && (
              <S.Error title={errors?.message || ''}>
                <FiAlertCircle color="#c53030" size={20} />
              </S.Error>
            )}
            {loading && <S.Spinner />}
            {options.length > 0 && (
              <S.ButtonLimpar
                type="button"
                title="Limpar"
                onClick={handleClear}
              >
                <MdCancel size={24} />
              </S.ButtonLimpar>
            )}
          </S.Container>
          <S.ListItens>
            {options &&
              options.map((opt: Option) => (
                <S.Item
                  onClick={() => handleItemSelected(opt)}
                  key={String(opt.id)}
                >
                  <strong>{opt.name}</strong>
                </S.Item>
              ))}
          </S.ListItens>
        </>
      )}
    />
  );
};

export default AutoCompleteMulti;
