import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaBoxOpen } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';
import {
  HeaderBackground,
  HeaderInfo,
  Input,
  Button,
  Label,
  FormGroup,
  Upload,
  Autocomplete,
  InputCurrency,
} from '../../../../components';

import { useToast } from '../../../../hooks/toast';
import { formatCurrencyDataBase } from '../../../../utils/helpers';

import api from '../../../../services/api';

import * as S from './styles';

interface Category {
  id: string;
  name: string;
}

const schema = Yup.object().shape({
  name: Yup.string().required('Produto obrigatório'),
  category_id: Yup.string().required('Categoria é obrigatório'),
  reference: Yup.string(),
  amount: Yup.number().required('Quantidade é obrigatório'),
  price: Yup.string().required('Preço é obrigatório'),
  price_cost: Yup.string().required('Preço de custo obrigatório'),
});
interface FormData {
  image_upload: FileList;
  image_url?: string;
  category_id: string;
  category?: Category;
  name: string;
  reference?: string;
  amount: number;
  price: number;
  price_cost: number;
}

interface Product {
  id: string;
  store_id: string;
  category: Category;
  category_id: string;
  name: string;
  reference?: string;
  amount: number;
  price: number;
  price_cost: number;
  deleteAt?: string;
  createdAt?: string;
  updateAt?: string;
}

interface RouterParams {
  id?: string | undefined;
}

const ProductForm: React.FC = () => {
  const { register, handleSubmit, errors, reset, control } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState<Product>();
  // const [categories, setCategories] = useState<Category[]>();
  const { id } = useParams<RouterParams>();

  const onSubmit = useCallback(
    async (data: FormData) => {
      // console.log('data Original', data);
      try {
        setLoading(true);
        /** Formata Dados */
        const priceFormatted = formatCurrencyDataBase(data.price);
        const priceCostFormatted = formatCurrencyDataBase(data.price_cost);
        const dataFormatted = {
          ...data,
          price: priceFormatted,
          price_cost: priceCostFormatted,
        };

        // console.log('DataFormatted', dataFormatted);

        if (id) {
          const {
            name,
            category_id,
            reference,
            amount,
            price,
            price_cost,
          } = dataFormatted;
          const response = await api.put<Product>(`/products/${id}`, {
            name,
            category_id,
            reference,
            amount,
            price,
            price_cost,
          });
          setProduct(response.data);
        } else {
          const formData = new FormData();
          if (data.image_upload.length) {
            formData.append('image', data.image_upload[0]);
          }
          formData.append('name', dataFormatted.name);
          formData.append('category_id', dataFormatted.category_id);
          formData.append('reference', String(dataFormatted?.reference));
          formData.append('amount', String(dataFormatted.amount));
          formData.append('price', String(dataFormatted.price));
          formData.append('price_cost', String(dataFormatted.price_cost));

          await api.post('/products', formData);
          reset();
        }

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Salvo com sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [addToast, id],
  );

  useEffect(() => {
    if (id) {
      api.get<Product>(`/products/${id}`).then((response) => {
        const { data } = response;
        reset(data);
        setProduct(data);
      });
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    reset({
      image_upload: '',
      image_url: '',
      category_id: '',
      name: '',
      reference: '',
      amount: 1,
      price: 0,
      price_cost: 0,
      category: { id: '', name: '' },
    });
    // eslint-disable-next-line
  }, []);

  return (
    <S.Container>
      <HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <MdKeyboardBackspace size={24} color="#fff" />
            <Link to="/products">Produtos</Link>
          </S.BackLink>
          <HeaderInfo
            title={id ? `Editando: ${product?.name}` : 'Novo Produto'}
            icon={FaBoxOpen}
          />
        </S.HeaderPageDatail>
      </HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          {!id && (
            <Upload control={control} register={register} name="image_upload" />
          )}
          <FormGroup>
            <Label>Produto</Label>
            <Input
              type="text"
              register={register}
              errors={errors.name}
              name="name"
              placeholder="Produto"
            />
          </FormGroup>
          <FormGroup>
            <Label>Categoria</Label>
            <Autocomplete
              register={register}
              errors={errors.category_id}
              control={control}
              name="category_id"
              placeholder="Categoria"
              url="/products/categories"
              defaultOption={product?.category}
            />
          </FormGroup>
          {/* <FormGroup>
            <Label>Categoria2</Label>
            <Select
              name="category_id2"
              placeholder="Categoria"
              options={[
                { id: undefined, name: undefined },
                { id: '1', name: 'Categoria 1' },
                { id: '2', name: 'Categoria 2' },
                { id: '3', name: 'Categoria 3' },
              ]}
            />
          </FormGroup> */}
          <FormGroup>
            <Label>Referência</Label>
            <Input
              type="text"
              register={register}
              errors={errors.reference}
              name="reference"
              placeholder="EX: A0001"
            />
          </FormGroup>
          <FormGroup>
            <Label>Quantidade</Label>
            <Input
              type="number"
              register={register}
              errors={errors.amount}
              name="amount"
              placeholder="Quantidade"
            />
          </FormGroup>
          <FormGroup>
            <Label>Preço</Label>
            <InputCurrency
              register={register}
              errors={errors.price}
              name="price"
              placeholder="Ex: 19,90"
            />
          </FormGroup>
          <FormGroup>
            <Label>Preço de Custo</Label>
            <InputCurrency
              register={register}
              errors={errors.price_cost}
              name="price_cost"
              placeholder="Ex: 19,90"
            />
          </FormGroup>
          <Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};

export default ProductForm;
