export const formatCurrencyBR = (value: number, prefix = true): string => {
  let numberFormatted;

  if (prefix) {
    numberFormatted = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  } else {
    numberFormatted = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  }
  return numberFormatted;
};

export const formatCurrencyDataBase = (value: string | number): number => {
  let newValue = String(value);
  newValue = newValue.replace(/\D/g, '');
  newValue = newValue.replace(/(\d)(\d{2})$/, '$1.$2');

  return Number(newValue);
};

export const numberOnly = (value: string): number => {
  return Number(value.replace(/\D/g, ''));
};

export const groupObjectByKey = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  key: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  // eslint-disable-next-line
 return values.reduce(function (r: any, a: any) {
    // eslint-disable-next-line no-param-reassign
    r[a[key]] = r[a[key]] || [];
    r[a[key]].push(a);
    return r;
  }, Object.create(null));
};
