import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';

interface Store {
  id: string;
  name: string;
  typeDocument: 'CNPJ' | 'PF';
  document: string;
  ie?: string | null;
  phone: string;
  cellPhone: string;
  email: string;
  zipcode: string;
  street: string;
  number: string;
  neighborhood: string;
  city: string;
  state: string;
  complement: string;
  note: string;
  status: true;
  createdAt: string;
  updatedAt: string;
}

type Role = {
  id: string;
  slug: string;
};
type Permission = {
  id: string;
  slug: string;
};

interface User {
  id: string;
  name: string;
  email: string;
  avatar_url: string;
  stores: Store[];
  roles: Role[];
  permissions: Permission[];
  users_roles: string[];
  users_permissions: string[];
}
interface AuthState {
  token: string;
  user: User;
  store: Store;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@sacolaDigital:token');
    const user = localStorage.getItem('@sacolaDigital:user');
    const currentStore = localStorage.getItem('@sacolaDigital:store');
    if (token && user && currentStore) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      const store = JSON.parse(currentStore);
      api.defaults.headers.store_id = store.id;
      return { token, user: JSON.parse(user), store };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const res = await api.post('sessions', { email, password });

    const { token, user } = res.data;
    const [currentStore] = user.stores;

    localStorage.setItem('@sacolaDigital:token', token);
    localStorage.setItem('@sacolaDigital:user', JSON.stringify(user));
    localStorage.setItem('@sacolaDigital:store', JSON.stringify(currentStore));

    api.defaults.headers.authorization = `Bearer ${token}`;
    api.defaults.headers.store_id = currentStore.id;

    setData({ token, user, store: currentStore });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@sacolaDigital:token');
    localStorage.removeItem('@sacolaDigital:user');
    localStorage.removeItem('@sacolaDigital:store');
    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      setData({
        ...data,
        token: data.token,
        user,
      });
      localStorage.setItem('@sacolaDigital:user', JSON.stringify(user));
    },
    [setData, data],
  );

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, updateUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
