import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Form from './Form';

const Permission: React.FC = () => {
  return (
    <Switch>
      <Route path="/permissions" component={List} isPrivate exact />
      <Route path="/permissions/new" component={Form} isPrivate />
      <Route path="/permissions/edit/:id" component={Form} isPrivate />
    </Switch>
  );
};

export default Permission;
