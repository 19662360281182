import React, { ReactElement } from 'react';
// import { NavLink } from 'react-router-dom';
import {
  // MdFormatListBulleted,
  MdShoppingCart,
  MdDashboard,
  MdAssignment,
  MdSwapHoriz,
  MdPermDataSetting,
} from 'react-icons/md';
import {
  // FaCashRegister,
  FaBoxOpen,
} from 'react-icons/fa';
import { GoSettings } from 'react-icons/go';
import {
  Container,
  MainMenu,
  // ItemMenu,
  // LabelMenu,
  // IconArrowRight,
  // SubmenuContainer,
} from './styles';
import { useSiedeBar } from '../../hooks/sidebar';
import ItemMenu from './ItemMenu';
// import './styles.css';

interface Menu {
  name: string;
  route: string;
  icon?: ReactElement | null;
  children: Menu[];
}

const SideBar: React.FC = () => {
  const { isOpened } = useSiedeBar();

  const menus: Menu[] = [
    {
      name: 'Dashboard',
      route: '/dashboard',
      icon: <MdDashboard size={24} color="#707070" />,
      children: [],
    },
    {
      name: 'Vendas',
      route: '/vendas',
      icon: <MdShoppingCart size={24} color="#707070" />,
      children: [],
    },
    {
      name: 'Estoque',
      route: '#',
      icon: <FaBoxOpen size={22} color="#707070" />,
      children: [
        {
          name: 'Produtos',
          route: '/products',
          icon: null,
          children: [],
        },
        {
          name: 'Categoria',
          route: '/categories',
          icon: null,
          children: [],
        },
      ],
    },
    {
      name: 'Financeiro',
      route: '/financeiro',
      icon: <MdSwapHoriz size={24} color="#707070" />,
      children: [],
    },
    {
      name: 'Relatórios',
      route: '/reports',
      icon: <MdAssignment size={24} color="#707070" />,
      children: [],
    },
    {
      name: 'Controle de ACL',
      route: '#',
      icon: <MdPermDataSetting size={24} color="#707070" />,
      children: [
        {
          name: 'Funções/Papeis',
          route: '/roles',
          icon: null,
          children: [],
        },
        {
          name: 'Permissões',
          route: '/permissions',
          icon: null,
          children: [],
        },
      ],
    },
    {
      name: 'Cadastros',
      route: '#',
      icon: <GoSettings size={24} color="#707070" />,
      children: [
        {
          name: 'Clientes',
          route: '/clientes',
          icon: null,
          children: [],
        },
        {
          name: 'Funcionários',
          route: '/funcionarios',
          icon: null,
          children: [],
        },
      ],
    },
  ];

  return (
    <Container isMenuOpened={isOpened}>
      <MainMenu>
        {menus.map((menu: Menu) => (
          <ItemMenu key={menu.name} menu={menu} />
        ))}
      </MainMenu>
    </Container>
  );
};

export default SideBar;
