import React, { InputHTMLAttributes, useState, useCallback } from 'react';
import { FieldError } from 'react-hook-form';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { Container, Error } from './styles';

type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined;

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: object;
  icon?: React.ComponentType<IconBaseProps>;
  register: RefReturn;
  errors: FieldError | undefined;
}

const InputCurrency: React.FC<InputProps> = ({
  name,
  containerStyle = {},
  icon: Icon,
  register,
  errors,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleKeyUp = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    let { value } = e.currentTarget;
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d)(\d{2})$/, '$1,$2');
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

    e.currentTarget.value = value;
  }, []);

  return (
    <Container
      style={containerStyle}
      isErrored={!!errors}
      isFocused={isFocused}
    >
      {Icon && <Icon size={20} />}

      <input
        type="text"
        ref={register}
        name={name}
        onKeyUp={handleKeyUp}
        onFocus={() => setIsFocused(true)}
        onBlur={handleInputBlur}
      />

      {/* <Controller
        control={control}
        ref={register}
        name={name}
        onKeyUp={handleKeyUp}
        onFocus={() => setIsFocused(true)}
        onBlur={handleInputBlur}
        defaultValue=""
        as={<input type="text" />}
      /> */}
      {errors && (
        <Error title={errors.message || ''}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </Container>
  );
};

export default InputCurrency;
