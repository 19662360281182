import React, { useState, useEffect } from 'react';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import { useDropzone, DropzoneInputProps } from 'react-dropzone';
import api from '../../services/api';
import * as S from './styles';
import FileList from './FileList';

export interface UploadFile {
  file: File;
  id: string;
  name: string;
  readableSize: string;
  preview: string;
  progress: number;
  uploaded: boolean;
  error: boolean;
  url: string | undefined;
}

type KeyValue = {
  [key: string]: string | number;
};

type Props = {
  params?: KeyValue;
  url: string;
  files?: UploadFile[];
};

type PropsWithDropZone = Props & DropzoneInputProps;

const UploadDropzone: React.FC<PropsWithDropZone> = ({
  params,
  url,
  files,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadFile[]>(files || []);

  function updateFile(id: string, data: UploadFile): void {
    // eslint-disable-next-line no-debugger
    // debugger;
    const newList = uploadedFiles.map((uploadedFile: UploadFile) => {
      const file =
        id === uploadedFile.id ? { ...uploadedFile, ...data } : uploadedFile;

      // console.log(file);
      return file;
    });

    setUploadedFiles(newList);
  }

  const processUpload = (uploadedFile: UploadFile): void => {
    if (!uploadedFile.file) {
      // console.log('NÃO EXISTE: ', uploadedFile);
      return;
    }
    // console.log('EXISTE: ', uploadedFile);

    const data = new FormData();

    if (params) {
      Object.keys(params).forEach((key: string) => {
        // const val = params[key] as string;
        const val = params[key] as string;
        data.append(key, val);
      });
    }
    data.append('image', uploadedFile.file, uploadedFile.name);
    api
      .post(url, data, {
        onUploadProgress: (e: ProgressEvent) => {
          // console.log(uploadedFile.id, e.loaded);
          const progress = Number(Math.round((e.loaded * 100) / e.total));
          updateFile(uploadedFile.id, {
            ...uploadedFile,
            progress,
          });
        },
      })
      .then((response) => {
        updateFile(uploadedFile.id, {
          ...uploadedFile,
          uploaded: true,
          id: response.data.id,
          url: response.data.image_url,
        });
      })
      .catch(() => {
        updateFile(uploadedFile.id, {
          ...uploadedFile,
          error: true,
        });
      });
  };

  function onDrop(dropFiles: File[]): void {
    const newUploadFiles: UploadFile[] = dropFiles.map((file: File) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: undefined,
    }));

    const newList = uploadedFiles.concat(newUploadFiles) as UploadFile[];

    /**
     *  Não está atualizando o STATE
     */
    setUploadedFiles(newList);
    newList.forEach(processUpload);
  }
  // const handleStartUpload = (): void => {
  //   uploadedFiles.forEach((file) => {
  //     if (file.uploaded !== true) {
  //       processUpload(file);
  //     }
  //   });
  // };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({ onDrop, accept: 'image/*', maxFiles: 4 });

  const renderDragMessage = (
    isActive: boolean,
    isReject: boolean,
  ): React.ReactElement => {
    if (!isActive) {
      return (
        <S.UploadMessage type="default">
          Arraste arquivos aqui...
        </S.UploadMessage>
      );
    }

    if (isReject) {
      return (
        <S.UploadMessage type="error">Arquivo não suportado</S.UploadMessage>
      );
    }

    return (
      <S.UploadMessage type="success">Solte os arquivos aqui</S.UploadMessage>
    );
  };

  async function handleDelete(id: string): Promise<void> {
    await api.delete(`${url}/${id}`);
    setUploadedFiles(uploadedFiles.filter((item) => item.id !== id));
  }

  useEffect(() => {
    return () => {
      uploadedFiles.forEach((file) => URL.revokeObjectURL(file.preview));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <S.DropContainer
        {...getRootProps({ refKey: 'innerRef', className: 'dropzone' })}
        isDragActive={isDragActive}
        isDragReject={isDragReject}
      >
        <input {...getInputProps()} />
        {renderDragMessage(isDragActive, isDragReject)}
      </S.DropContainer>
      {!!uploadedFiles.length && (
        <FileList files={uploadedFiles} onDelete={handleDelete} />
      )}
      {/* <button type="button" onClick={handleStartUpload}>
        Start Upload
      </button> */}
    </>
  );
};

export default UploadDropzone;
