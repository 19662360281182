import React, { createContext, useCallback, useState, useContext } from 'react';

interface ThemeState {
  theme: string;
}

interface ThemeContextData {
  theme: string;
  onChangeTheme(themeSelected: string): void;
}

const ThemeContext = createContext<ThemeContextData>({} as ThemeContextData);

const ThemeProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<ThemeState>(() => {
    const theme = localStorage.getItem('@sacolaDigital:theme');

    if (theme === 'dark') {
      document.body.classList.remove('ligth-theme');
      document.body.classList.add('dark-theme');
      return { theme } as ThemeState;
    }
    document.body.classList.remove('dark-theme');
    document.body.classList.add('light-theme');

    return { theme: 'light' } as ThemeState;
  });

  const onChangeTheme = useCallback((themeSelected: string) => {
    localStorage.setItem('@sacolaDigital:theme', themeSelected);
    setData({ theme: themeSelected });

    if (themeSelected === 'dark') {
      document.body.classList.remove('ligth-theme');
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.remove('dark-theme');
      document.body.classList.add('light-theme');
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ theme: data.theme, onChangeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

function useTheme(): ThemeContextData {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useTheme must be used within anThemeProvider');
  }

  return context;
}

export { ThemeProvider, useTheme };
