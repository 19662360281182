import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
// import ForgotPassword from '../pages/ForgotPassword';
// import ResetPassword from '../pages/ResetPassword';
import Dashboard from '../pages/Dashboard';
import Profile from '../pages/Profile';
import Product from '../pages/Product/Product';
import Category from '../pages/Product/Category';
import Role from '../pages/Role';
import Permission from '../pages/Permission';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Redirect path="/" to="/signin" exact />
      <Route path="/signin" component={SignIn} />
      <Route path="/signup" component={SignUp} />
      {/* <Route path="/forgot-password" component={ForgotPassword} /> */}
      {/* <Route path="/reset-password" component={ResetPassword} /> */}

      <Route path="/dashboard" component={Dashboard} isPrivate exact />

      <Route path="/products" component={Product} isPrivate />

      <Route path="/categories/" component={Category} isPrivate />

      <Route path="/roles" component={Role} isPrivate />

      <Route path="/permissions" component={Permission} isPrivate />

      <Route path="/profile" component={Profile} isPrivate />
    </Switch>
  );
};

export default Routes;
