import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useHistory } from 'react-router-dom';
import { FaUserCog } from 'react-icons/fa';
import { MdSearch } from 'react-icons/md';
import {
  InputSearch,
  ButtonLink,
  HeaderBackground,
  HeaderPage,
  HeaderInfo,
  Table,
  Pagination,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Position,
  Button,
  PopUpMenu,
} from '../../../components';
import { useToast } from '../../../hooks/toast';
import { ModalHandles } from '../../../components/Modal';

import api from '../../../services/api';

import * as S from './styles';

interface Permission {
  id: string;
  slug: string;
  description: string;
  createdAt: string;
  updateAt: string;
}
interface Paginate {
  data: Permission[];
  page: number;
  per_page: number;
  last_page: number;
  total: number;
}

const Permission: React.FC = () => {
  const [permissions, setPermissions] = useState<Paginate>();
  const [permission, setPermission] = useState<Permission>();
  const [term, setTerm] = useState('');
  const { addToast } = useToast();
  const history = useHistory();
  const modalRemoverRef = useRef<ModalHandles>(null);

  useEffect(() => {
    const params = {
      page: 1,
      per_page: 10,
    };

    api
      .get<Paginate>('permissions', { params })
      .then((response) => {
        const { data } = response;
        setPermissions(data);
      });
  }, []);

  const handleSearch = useCallback(
    async ({ page, per_page }) => {
      const params = {
        page,
        per_page,
        term,
      };

      const response = await api.get('/permissions', { params });
      const { data } = response;
      setPermissions(data);
    },
    [term],
  );

  const handleRemove = async (): Promise<void> => {
    try {
      await api.delete(`/permissions/${permission?.id}`);
      if (!permissions) return;
      const newList = permissions.data.filter(
        (item) => item.id !== permission?.id,
      );
      setPermissions({ ...permissions, data: newList });
      modalRemoverRef.current?.closeModal();
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Permissões removido com sucesso',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ops, Erro',
        description: 'Ocorreu um erro ao inserir um novo registro',
      });
    }
  };

  const handleOpenModalRemover = useCallback(
    (permissionSelected: Permission): void => {
      setPermission(permissionSelected);
      modalRemoverRef.current?.openModal();
    },
    [],
  );

  return (
    <S.Container>
      <HeaderBackground>
        <HeaderPage>
          <HeaderInfo title="Permissões" icon={FaUserCog} />

          <S.ContainerSearch>
            <InputSearch
              title="Digite aqui o que deseja pesquisar"
              icon={MdSearch}
              placeholder="Pesquisar"
              onChange={(e) => setTerm(e.target.value)}
              onKeyDown={(e) => {
                e.key === 'Enter'
                  ? handleSearch({ page: 1, per_page: permissions?.per_page })
                  : {};
              }}
            />
          </S.ContainerSearch>

          <div>
            <ButtonLink title="Adicionar na Permissões" to="/permissions/new">
              Adicionar
            </ButtonLink>
          </div>
        </HeaderPage>
      </HeaderBackground>

      <S.Panel>
        <div className="tbl-content">
          <Table>
            <thead>
              <tr>
                <th className="text-left">Slug</th>
                <th className="text-left">Description</th>
                <th className="text-center">Ação</th>
              </tr>
            </thead>
            <tbody>
              {permissions &&
                permissions.data.map((item: Permission) => (
                  <tr key={item.id}>
                    <td>{item.slug}</td>
                    <td>{item.description}</td>
                    <td className="text-center">
                      <PopUpMenu>
                        <button
                          type="button"
                          onClick={() =>
                            history.push(`/permissions/edit/${item.id}`)
                          }
                        >
                          <S.IconEdit
                            title="Editar"
                            size={24}
                            color="#707070"
                          />
                          Editar
                        </button>
                        <button
                          type="button"
                          onClick={() => handleOpenModalRemover(item)}
                        >
                          <S.IconDelete
                            title="Remover"
                            size={24}
                            color="#707070"
                          />
                          Remover
                        </button>
                      </PopUpMenu>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>

          {/* <pre>{JSON.stringify(permissions, null, 2)}</pre> */}
        </div>
        <S.PaginationContainer>
          {permissions && (
            <Pagination
              page={permissions?.page}
              total={permissions?.total}
              per_page={permissions?.per_page}
              last_page={permissions?.last_page}
              onSearch={handleSearch}
            />
          )}
        </S.PaginationContainer>
      </S.Panel>

      <Modal ref={modalRemoverRef} options={{ width: '400px' }}>
        <ModalHeader>
          <ModalTitle>Você Confirma a EXCLUSÃO?</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>O registro será removido completamente!</p>
        </ModalBody>
        <ModalFooter>
          <Position align="right">
            <Button
              variant="outline-secondary"
              onClick={() => modalRemoverRef.current?.closeModal()}
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={() => handleRemove()}
              style={{ marginLeft: '16px' }}
            >
              SIM, confirmo a exclusão
            </Button>
          </Position>
        </ModalFooter>
      </Modal>
    </S.Container>
  );
};

export default Permission;
