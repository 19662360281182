import React, { ChangeEvent, useState, useCallback, useEffect } from 'react';
import { Control } from 'react-hook-form';
import { MdCameraAlt } from 'react-icons/md';

import * as S from './styles';
import './styles';

type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined;

interface Props {
  name: string;
  imageUrl?: string;
  register: RefReturn;
  control: Control;
}

const Upload: React.FC<Props> = ({
  name,
  imageUrl = '',
  register,
  control,
}) => {
  const [preview, setPreview] = useState(imageUrl);

  const handlePreview = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      setPreview('');
    }
    const previewURL = URL.createObjectURL(file);
    setPreview(previewURL);
  }, []);

  useEffect(() => {
    setPreview(imageUrl);
  }, [imageUrl]);

  useEffect(() => {
    return () => {
      if (preview) {
        URL.revokeObjectURL(preview);
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const id = control?.defaultValuesRef?.current?.id;
    if (!id) {
      setPreview('');
    }
  }, [control]);
  return (
    <S.Container preview={preview}>
      <input
        id="input"
        type="file"
        name={name}
        ref={register}
        onChange={handlePreview}
      />
      {!preview && <MdCameraAlt size={24} />}
    </S.Container>
  );
};

export default Upload;
