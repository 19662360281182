import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiMail, FiLock, FiUser, FiArrowLeft } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { useToast } from '../../hooks/toast';
import { Input, Button } from '../../components';

import logoImg from '../../assets/logo.png';
import { Container, Content, Background, AnimationContainer } from './styles';
import api from '../../services/api';

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

const schema = Yup.object().shape({
  name: Yup.string().required('Nome obrigatório'),
  email: Yup.string()
    .required('Email obrigatório')
    .email('Digite um e-mail válido'),
  password: Yup.string().min(6, 'No mínimo 6 dígitos'),
});

const SignUp: React.FC = () => {
  const { register, handleSubmit, errors } = useForm<SignUpFormData>({
    resolver: yupResolver(schema),
  });
  const { addToast } = useToast();
  const history = useHistory();
  const onSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        const { name, email, password } = data;
        await api.post('users', {
          name,
          email,
          password,
        });

        addToast({
          type: 'success',
          title: 'Cadastro realizado!',
          description: 'Você já pode fazer seu login no Sacola Digital!',
        });

        history.push('/signin');
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro no cadastro',
          description: 'Ocorreu um erro ao fazer cadastro, tente novamente',
        });
      }
    },
    [history, addToast],
  );

  return (
    <Container>
      <Background />
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="Sacola Digital" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <h1>Faça seu cadastro</h1>

            <Input
              register={register}
              errors={errors.name}
              type="text"
              name="name"
              icon={FiUser}
              placeholder="Nome"
            />
            <Input
              register={register}
              errors={errors.email}
              type="text"
              name="email"
              icon={FiMail}
              placeholder="E-mail"
            />
            <Input
              register={register}
              errors={errors.password}
              type="password"
              name="password"
              icon={FiLock}
              autoComplete="off"
              placeholder="Senha"
            />

            <Button type="submit">Cadastrar</Button>
          </form>

          <Link to="/signin">
            <FiArrowLeft />
            Voltar para logon
          </Link>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignUp;
