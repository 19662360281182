import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FiLogIn, FiMail, FiLock } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import { Input, Button } from '../../components';

import logoImg from '../../assets/logo.png';
import { Container, Content, Background, AnimationContainer } from './styles';

interface SignInFormData {
  email: string;
  password: string;
}

const schema = Yup.object().shape({
  email: Yup.string()
    .required('Email obrigatório')
    .email('Digite um e-mail válido'),
  password: Yup.string().required('Senha obrigatória'),
});

const SignIn: React.FC = () => {
  const { register, handleSubmit, errors } = useForm<SignInFormData>({
    resolver: yupResolver(schema),
  });

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const onSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        await signIn({
          email: data.email,
          password: data.password,
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro na autenricação',
          description:
            'Ocorreu um erro ao fazer login, verifique as credenciais EMAIL / SENHA',
        });
      }
    },
    [signIn, addToast],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="Sacola Digital" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <h1>Faça seu logon</h1>

            <Input
              type="text"
              name="email"
              icon={FiMail}
              placeholder="E-mail"
              register={register}
              errors={errors.email}
            />
            <Input
              type="password"
              name="password"
              icon={FiLock}
              placeholder="Senha"
              autoComplete="off"
              register={register}
              errors={errors.email}
            />

            <Button variant="primary" type="submit">
              Entrar
            </Button>

            <Link to="/forgot-password">Esqueci minha senha</Link>
          </form>

          <Link to="/signup">
            <FiLogIn />
            Criar conta
          </Link>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};

export default SignIn;
