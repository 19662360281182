import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaTags } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';
import {
  HeaderBackground,
  HeaderInfo,
  Input,
  Button,
  Label,
  FormGroup,
  Checkbox,
  Upload,
} from '../../../../components';

import { useToast } from '../../../../hooks/toast';

import api from '../../../../services/api';

import * as S from './styles';

interface FormData {
  name: string;
  image_upload: FileList;
  image?: string;
  image_url?: string;
  status: boolean;
}

const schema = Yup.object().shape({
  name: Yup.string().required('Categoria obrigatório'),
  // image: Yup.mixed().required('Image é obrigatório'),
  status: Yup.boolean(),
});

interface Category {
  id: string;
  name: string;
  image?: string;
  image_url?: string;
  status: boolean;
  createdAt?: string;
  updateAt?: string;
}

interface RouterParams {
  id?: string | undefined;
}

const CategoryForm: React.FC = () => {
  const { register, handleSubmit, errors, reset, control } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<Category>();
  const { id } = useParams<RouterParams>();

  const onSubmit = useCallback(
    async (data: FormData) => {
      // console.log('data', data);
      try {
        setLoading(true);

        const formData = new FormData();
        const { name, status, image_upload } = data;

        if (image_upload?.length) {
          formData.append('image', image_upload[0]);
        }
        formData.append('data', JSON.stringify({ name, status }));

        if (id) {
          const response = await api.put<Category>(
            `/products/categories/${id}`,
            formData,
          );
          setCategory(response.data);
        } else {
          await api.post('/products/categories', formData);
          reset();
        }

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Salvo com sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [addToast, id],
  );
  useEffect(() => {
    reset({
      name: '',
      status: true,
      image_upload: undefined,
      image: undefined,
      image_url: undefined,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    api.get<Category>(`/products/categories/${id}`).then((response) => {
      const { data } = response;
      setCategory(data);
      reset(data);
    });
    // eslint-disable-next-line
  }, [id]);

  return (
    <S.Container>
      <HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <MdKeyboardBackspace size={24} color="#fff" />
            <Link to="/categories">Categorias</Link>
          </S.BackLink>
          <HeaderInfo
            title={id ? `Editando: ${category?.name}` : 'Nova Categoria'}
            icon={FaTags}
          />
        </S.HeaderPageDatail>
      </HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Upload
            control={control}
            register={register}
            name="image_upload"
            imageUrl={category?.image_url}
          />
          <FormGroup>
            <Label>Categoria</Label>
            <Input
              type="text"
              register={register}
              errors={errors.name}
              name="name"
              placeholder="Categoria"
            />
          </FormGroup>
          <FormGroup>
            <Checkbox
              register={register}
              name="status"
              items={[{ id: 1, name: 'Ativo ?' }]}
            />
          </FormGroup>
          <Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};

export default CategoryForm;
