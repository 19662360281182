import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useHistory } from 'react-router-dom';
import { FaUserCog } from 'react-icons/fa';
import { MdSearch } from 'react-icons/md';
import {
  InputSearch,
  ButtonLink,
  HeaderBackground,
  HeaderPage,
  HeaderInfo,
  Table,
  Pagination,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Position,
  Button,
  Loader,
  TableResponsive,
  PopUpMenu,
} from '../../../components';
import { ModalHandles } from '../../../components/Modal';

import { useToast } from '../../../hooks/toast';

import api from '../../../services/api';

import * as S from './styles';

interface Role {
  id: string;
  slug: string;
  description: string;
  createdAt: string;
  updateAt: string;
}
interface Paginate {
  data: Role[];
  page: number;
  per_page: number;
  last_page: number;
  total: number;
}

const Role: React.FC = () => {
  const [roles, setRoles] = useState<Paginate>();
  const [role, setRole] = useState<Role>();
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState('');
  const { addToast } = useToast();
  const history = useHistory();
  const modalRemoverRef = useRef<ModalHandles>(null);

  useEffect(() => {
    const params = {
      page: 1,
      per_page: 10,
    };

    setLoading(true);
    api
      .get<Paginate>('roles', { params })
      .then((response) => {
        const { data } = response;
        setRoles(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSearch = useCallback(
    async ({ page, per_page }) => {
      try {
        setLoading(true);
        const params = {
          page,
          per_page,
          term,
        };
        const response = await api.get('/roles', { params });
        const { data } = response;
        setRoles(data);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ops...',
          description: 'Ops, ocorreu um erro no processo de pesquisa',
        });
      } finally {
        setLoading(false);
      }
    },
    [term, addToast],
  );

  const handleRemove = async (): Promise<void> => {
    try {
      await api.delete(`/roles/${role?.id}`);
      if (!roles) return;
      const newList = roles.data.filter((item) => item.id !== role?.id);
      setRoles({ ...roles, data: newList });
      modalRemoverRef.current?.closeModal();
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Função/Papel removido com sucesso',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ops, Erro',
        description: 'Ocorreu um erro ao inserir um novo registro',
      });
    }
  };

  const handleOpenModalRemover = useCallback((roleSelected: Role): void => {
    setRole(roleSelected);
    modalRemoverRef.current?.openModal();
  }, []);

  return (
    <S.Container>
      <HeaderBackground>
        <HeaderPage>
          <HeaderInfo title="Função / Papel" icon={FaUserCog} />

          <S.ContainerSearch>
            <InputSearch
              title="Digite aqui o que deseja pesquisar"
              icon={MdSearch}
              placeholder="Pesquisar"
              onChange={(e) => setTerm(e.target.value)}
              onKeyDown={(e) =>
                e.key === 'Enter'
                  ? handleSearch({ page: 1, per_page: roles?.per_page })
                  : {}
              }
            />
          </S.ContainerSearch>

          <div>
            <ButtonLink title="Adicionar na Função/Papel" to="/roles/new">
              Adicionar
            </ButtonLink>
          </div>
        </HeaderPage>
      </HeaderBackground>

      <S.Panel>
        {loading ? (
          <Loader loading={loading} align="center" message="Carregando..." />
        ) : (
          <>
            <TableResponsive>
              <Table>
                <thead>
                  <tr>
                    <th className="text-left">Slug</th>
                    <th className="text-left">Description</th>
                    <th className="text-center">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {roles &&
                    roles.data.map((item: Role) => (
                      <tr key={item.id}>
                        <td>{item.slug}</td>
                        <td>{item.description}</td>
                        <td className="text-center">
                          <PopUpMenu>
                            <button
                              type="button"
                              onClick={() =>
                                history.push(`/roles/edit/${item.id}`)
                              }
                            >
                              <S.IconEdit
                                title="Editar"
                                size={24}
                                color="#707070"
                              />
                              Editar
                            </button>
                            <button
                              type="button"
                              onClick={() => handleOpenModalRemover(item)}
                            >
                              <S.IconDelete
                                title="Remover"
                                size={24}
                                color="#707070"
                              />
                              Remover
                            </button>
                          </PopUpMenu>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              {/* <pre>{JSON.stringify(roles, null, 2)}</pre> */}
            </TableResponsive>
            <S.FooterContainer>
              {roles && (
                <Pagination
                  page={roles?.page}
                  total={roles?.total}
                  per_page={roles?.per_page}
                  last_page={roles?.last_page}
                  onSearch={handleSearch}
                />
              )}
            </S.FooterContainer>
          </>
        )}
      </S.Panel>

      <Modal ref={modalRemoverRef} options={{ width: '400px' }}>
        <ModalHeader>
          <ModalTitle>Você Confirma a EXCLUSÃO?</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>O registro será removido completamente!</p>
        </ModalBody>
        <ModalFooter>
          <Position align="right">
            <Button
              variant="outline-secondary"
              onClick={() => modalRemoverRef.current?.closeModal()}
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={() => handleRemove()}
              style={{ marginLeft: '16px' }}
            >
              SIM, confirmo a exclusão
            </Button>
          </Position>
        </ModalFooter>
      </Modal>
    </S.Container>
  );
};

export default Role;
