import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaUserCog } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';
import {
  HeaderBackground,
  HeaderInfo,
  Input,
  Button,
  Label,
  FormGroup,
} from '../../../components';
import * as S from './styles';

import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';

interface FormData {
  slug: string;
  description: string;
}
const schema = Yup.object().shape({
  slug: Yup.string().required('Slug obrigatório'),
  description: Yup.string().required('Descrição obrigatória'),
});
interface Permission {
  id: string;
  slug: string;
  description: string;
  createdAt?: string;
  updateAt?: string;
}

interface RouterParams {
  id?: string | undefined;
}

const PermissionForm: React.FC = () => {
  const { register, handleSubmit, errors, reset } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [permission, setPermission] = useState<Permission>();
  const { id } = useParams<RouterParams>();

  const onSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        if (id) {
          const response = await api.put<Permission>(`permissions/${id}`, data);
          setPermission(response.data);
        } else {
          await api.post('permissions', data);
          reset();
        }
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Salvo com sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [addToast, id],
  );

  useEffect(() => {
    api.get<Permission>(`permissions/${id}`).then((response) => {
      const { data } = response;
      setPermission(data);
      reset(data);
    });
    // eslint-disable-next-line
  }, [id]);

  return (
    <S.Container>
      <HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <MdKeyboardBackspace size={24} color="#fff" />
            <Link to="/permissions">Permissões</Link>
          </S.BackLink>
          <HeaderInfo
            title={id ? `Editando: ${permission?.slug}` : 'Nova Permissão'}
            icon={FaUserCog}
          />
        </S.HeaderPageDatail>
      </HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>Slug</Label>
            <Input
              type="text"
              register={register}
              errors={errors.slug}
              name="slug"
              placeholder="Slug"
            />
          </FormGroup>

          <FormGroup>
            <Label>Descrição</Label>
            <Input
              type="text"
              register={register}
              errors={errors.description}
              name="description"
              placeholder="Descrição"
            />
          </FormGroup>
          <Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};

export default PermissionForm;
