import React, { useCallback, ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FiMail, FiLock, FiUser, FiCamera } from 'react-icons/fi';
import * as Yup from 'yup';

import { FaUser } from 'react-icons/fa';
import { useToast } from '../../hooks/toast';
import {
  Input,
  Button,
  HeaderBackground,
  HeaderInfo,
  AutocompleteMulti,
} from '../../components';

import { Option } from '../../components/AutocompleteMulti';

import { useAuth } from '../../hooks/auth';

import api from '../../services/api';

import avartarImgDefault from '../../assets/avatar.png';

import * as S from './styles';

interface ProfileFormData {
  name: string;
  email: string;
  old_password: string;
  password: string;
  password_confirmation: string;
  roles: string;
}

// type ProfileProps = {
//   name: string;
//   email: string;
//   old_password: string;
//   password: string;
//   password_confirmation: string;
//   roles: Role[];
// };

const schema = Yup.object().shape({
  name: Yup.string().required('Nome obrigatório'),
  email: Yup.string()
    .required('Email obrigatório')
    .email('Digite um e-mail válido'),
  roles: Yup.string().required('Perfil / Papel é obrigatório'),
  old_password: Yup.string(),
  password: Yup.string().when('old_password', {
    is: (val) => !!val.length,
    then: Yup.string().required('Senha Atual Obrigatória'),
    otherwise: Yup.string(),
  }),
  password_confirmation: Yup.string()
    .when('old_password', {
      is: (val) => !!val.length,
      then: Yup.string().required('Nova Senha Obrigatória'),
      otherwise: Yup.string(),
    })
    .oneOf([Yup.ref('password'), undefined], 'Confirmação incorreta'),
});

const Profile: React.FC = () => {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
  } = useForm<ProfileFormData>({
    resolver: yupResolver(schema),
  });
  const { addToast } = useToast();
  const { user, updateUser } = useAuth();
  const [roles, setRoles] = useState<Option[]>();

  const avatarImg = user.avatar_url ? user.avatar_url : avartarImgDefault;

  const onSubmit = useCallback(
    async (data: ProfileFormData) => {
      try {
        const {
          name,
          email,
          old_password,
          password,
          password_confirmation,
        } = data;

        const arrayRoles = data.roles.split(',');

        const formData = {
          name,
          email,
          roles: arrayRoles.length > 1 ? arrayRoles : [data.roles],
          ...(old_password
            ? {
                old_password,
                password,
                password_confirmation,
              }
            : {}),
        };

        const response = await api.put('/profile', formData);

        addToast({
          type: 'success',
          title: 'Perfil atualizado!',
          description:
            'Suas informações do perfil foram atualizadas com sucesso!',
        });

        updateUser(response.data);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro na atualização',
          description: 'Ocorreu um erro ao atualizar perfil, tente novamente',
        });
      }
    },
    [addToast, updateUser],
  );

  const handleAvatarChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const data = new FormData();

        data.append('avatar', e.target.files[0]);

        api.patch('/users/avatar', data).then((response) => {
          addToast({
            type: 'success',
            title: 'Avatar atualizado!',
            description: '',
          });
          updateUser(response.data);
        });
      }
    },
    [addToast, updateUser],
  );

  useEffect(() => {
    const rolesDefault = user.roles?.map((item) => ({
      id: item.id,
      name: item.slug,
    }));
    setRoles(rolesDefault);
    reset({ ...user, roles: '' });
    // eslint-disable-next-line
  }, []);

  return (
    <S.Container>
      <HeaderBackground>
        <S.HeaderPageDatail>
          <HeaderInfo title="Meu Perfil" icon={FaUser} />
        </S.HeaderPageDatail>
      </HeaderBackground>
      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.AvatarInput>
            <img src={avatarImg} alt={user.name} />
            <label htmlFor="avatar">
              <FiCamera />
              <input type="file" id="avatar" onChange={handleAvatarChange} />
            </label>
          </S.AvatarInput>

          <Input
            type="text"
            register={register}
            errors={errors.name}
            name="name"
            icon={FiUser}
            placeholder="Nome"
          />
          <Input
            type="text"
            register={register}
            errors={errors.email}
            name="email"
            icon={FiMail}
            placeholder="E-mail"
            readOnly
          />

          <div style={{ position: 'relative' }}>
            <AutocompleteMulti
              control={control}
              register={register}
              errors={errors.roles}
              name="roles"
              url="/roles/search"
              defaultOption={roles}
              labelValue="slug"
              keyValue="id"
            />
          </div>

          <Input
            type="password"
            register={register}
            errors={errors.old_password}
            containerStyle={{ marginTop: 24 }}
            name="old_password"
            icon={FiLock}
            autoComplete="off"
            placeholder="Senha Atual"
          />

          <Input
            type="password"
            register={register}
            errors={errors.password}
            name="password"
            icon={FiLock}
            autoComplete="off"
            placeholder="Nova Senha"
          />

          <Input
            type="password"
            register={register}
            errors={errors.password_confirmation}
            name="password_confirmation"
            icon={FiLock}
            autoComplete="off"
            placeholder="Confirmar Senha"
          />

          <Button type="submit">Confirmar mudanças</Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};

export default Profile;
