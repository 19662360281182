import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FaBoxOpen } from 'react-icons/fa';
import { MdSearch } from 'react-icons/md';
import fileSize from 'filesize';
import {
  InputSearch,
  ButtonLink,
  HeaderBackground,
  HeaderPage,
  HeaderInfo,
  Table,
  Pagination,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Position,
  Button,
  Loader,
  TableResponsive,
  PopUpMenu,
  Status,
  Image,
  UploadDropzone,
} from '../../../../components';
import { useToast } from '../../../../hooks/toast';
import { ModalHandles } from '../../../../components/Modal';
import { formatCurrencyBR } from '../../../../utils/helpers';
import { UploadFile } from '../../../../components/UploadDropzone';

import api from '../../../../services/api';

import * as S from './styles';

interface Category {
  id: string;
  name: string;
  image: string;
  status: boolean;
  image_url: string;
}
interface ProductImage {
  id: string;
  produto_id: string;
  image: string;
  image_url: string;
}

interface Product {
  id: string;
  category_id: string;
  name: string;
  reference: string;
  amount: number;
  price: number;
  price_cost: number;
  priceFormatted: string;
  priceCostFormatted: string;
  category: Category;
  product_image: ProductImage[];
  deleted_at: string;
}
interface Paginate {
  data: Product[];
  page: number;
  per_page: number;
  last_page: number;
  total: number;
}

interface ProductImages {
  id: string;
  product_id: string;
  image: string;
  image_url: string;
  size: number;
}

// interface ParamsDropzone {
//   product_id: string;
// }

type KeyValue = {
  [key: string]: string | number;
};

const Product: React.FC = () => {
  const [products, setProducts] = useState<Paginate>();
  const [product, setProduct] = useState<Product>();
  const [paramsDropZone, setParamsDropZone] = useState<KeyValue>();
  const [uploadedFiles, setUploadedFiles] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState('');
  const { addToast } = useToast();
  const history = useHistory();
  const modalRemoverRef = useRef<ModalHandles>(null);
  const modalUploadRef = useRef<ModalHandles>(null);

  const handleSearch = useCallback(
    async ({ page, per_page }) => {
      try {
        setLoading(true);
        const params = {
          page,
          per_page,
          term,
        };
        const response = await api.get<Paginate>('/products', { params });
        const { data } = response;
        const productsFormatted = data.data.map((item: Product) => {
          return {
            ...item,
            priceFormatted: formatCurrencyBR(item.price),
            priceCostFormatted: formatCurrencyBR(item.price_cost),
          };
        });

        setProducts({
          ...data,
          data: productsFormatted,
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ops...',
          description: 'Ops, ocorreu um erro no processo de pesquisa',
        });
      } finally {
        setLoading(false);
      }
    },
    [term, addToast],
  );

  const handleRemove = async (): Promise<void> => {
    try {
      await api.delete(`/products/${product?.id}`);
      if (!products) return;
      const newList = products.data.filter((item) => item.id !== product?.id);
      setProducts({ ...products, data: newList });
      modalRemoverRef.current?.closeModal();
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Produtos removido com sucesso',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ops, Erro',
        description: 'Ocorreu um erro ao inserir um novo registro',
      });
    }
  };

  const loadProductImages = useCallback(async (product_id) => {
    const response = await api.get<ProductImages[]>(
      `/products/images/${product_id}`,
    );

    const { data } = response;

    const images = data.map(
      (item: ProductImages) =>
        ({
          id: item.id,
          name: item.image,
          readableSize: fileSize(item.size),
          preview: item.image_url,
          uploaded: true,
          url: item.image_url,
        } as UploadFile),
    );

    setUploadedFiles(images);
  }, []);

  const handleOpenModalRemover = useCallback(
    (productSelected: Product): void => {
      setProduct(productSelected);
      modalRemoverRef.current?.openModal();
    },
    [],
  );

  const handleOpenModalUpload = useCallback(
    async (productSelected: Product): Promise<void> => {
      await loadProductImages(productSelected.id);
      setProduct(productSelected);

      const paramsDrop = {
        product_id: productSelected.id,
      } as KeyValue;

      setParamsDropZone(paramsDrop);
      modalUploadRef.current?.openModal();
    },
    [loadProductImages],
  );

  useEffect(() => {
    const params = {
      page: 1,
      per_page: 10,
    };

    handleSearch(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Container>
      <HeaderBackground>
        <HeaderPage>
          <HeaderInfo title="Produtos" icon={FaBoxOpen} />

          <S.ContainerSearch>
            <InputSearch
              title="Digite aqui o que deseja pesquisar"
              icon={MdSearch}
              placeholder="Pesquisar"
              onChange={(e) => setTerm(e.target.value)}
              onKeyDown={(e) =>
                e.key === 'Enter'
                  ? handleSearch({ page: 1, per_page: products?.per_page })
                  : {}
              }
            />
          </S.ContainerSearch>

          <div>
            <ButtonLink title="Adicionar na Produto" to="/products/new">
              Adicionar
            </ButtonLink>
          </div>
        </HeaderPage>
      </HeaderBackground>

      <S.Panel>
        {loading ? (
          <Loader loading={loading} align="center" message="Carregando..." />
        ) : (
          <>
            <TableResponsive>
              <Table>
                <thead>
                  <tr>
                    <th className="text-left">Satus</th>
                    <th className="text-left">Categoria</th>
                    <th className="text-left">Ref:</th>
                    <th className="text-center">Image</th>
                    <th className="text-left">Produto</th>
                    <th className="text-right">Quantidade</th>
                    <th className="text-right">Preço</th>
                    <th className="text-center">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {products &&
                    products.data.map((item: Product) => (
                      <tr key={item.id}>
                        <td className="text-left">
                          <Status enable={String(item.deleted_at === null)} />
                        </td>
                        <td className="text-left">{item.category?.name}</td>
                        <td className="text-left">{item.reference}</td>
                        <td className="text-center">
                          {item.product_image.length > 0 && (
                            <Image
                              rounded
                              height="80"
                              width="80"
                              src={item.product_image[0].image_url}
                              alt={item.name}
                            />
                          )}
                        </td>
                        <td>{item.name}</td>
                        <td className="text-right">{item.amount}</td>
                        <td className="text-right">{item.priceFormatted}</td>
                        <td className="text-center">
                          <PopUpMenu>
                            <button
                              type="button"
                              onClick={() =>
                                history.push(`/products/edit/${item.id}`)
                              }
                            >
                              <S.IconEdit
                                title="Editar"
                                size={24}
                                color="#707070"
                              />
                              Editar
                            </button>
                            <button
                              type="button"
                              onClick={() => handleOpenModalUpload(item)}
                            >
                              <S.IconUpload
                                title="Fotos"
                                size={24}
                                color="#707070"
                              />
                              Fotos
                            </button>
                            <button
                              type="button"
                              onClick={() => handleOpenModalRemover(item)}
                            >
                              <S.IconDelete
                                title="Remover"
                                size={24}
                                color="#707070"
                              />
                              Remover
                            </button>
                          </PopUpMenu>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              {/* <pre>{JSON.stringify(products, null, 2)}</pre> */}
            </TableResponsive>
            <S.FooterContainer>
              {products && (
                <Pagination
                  page={products?.page}
                  total={products?.total}
                  per_page={products?.per_page}
                  last_page={products?.last_page}
                  onSearch={handleSearch}
                />
              )}
            </S.FooterContainer>
          </>
        )}
      </S.Panel>

      <Modal ref={modalUploadRef} options={{ width: '800px' }}>
        <ModalHeader>
          <ModalTitle>Fotos: {product?.name}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <UploadDropzone
            files={uploadedFiles}
            url="products/images"
            params={paramsDropZone}
          />
        </ModalBody>
      </Modal>

      <Modal ref={modalRemoverRef} options={{ width: '400px' }}>
        <ModalHeader>
          <ModalTitle>Você Confirma a EXCLUSÃO?</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>O registro será removido completamente!</p>
        </ModalBody>
        <ModalFooter>
          <Position align="right">
            <Button
              variant="outline-secondary"
              onClick={() => modalRemoverRef.current?.closeModal()}
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={() => handleRemove()}
              style={{ marginLeft: '16px' }}
            >
              SIM, confirmo a exclusão
            </Button>
          </Position>
        </ModalFooter>
      </Modal>
    </S.Container>
  );
};

export default Product;
