import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaUserCog } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';
import {
  HeaderBackground,
  HeaderInfo,
  Input,
  Button,
  Label,
  FormGroup,
  Checkbox,
} from '../../../components';
import * as S from './styles';

import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import { groupObjectByKey } from '../../../utils/helpers';

type Permission = {
  id: string;
  slug: string;
  description: string;
};
interface FormData {
  slug: string;
  description: string;
  permissions: string[];
}

const schema = Yup.object().shape({
  slug: Yup.string().required('Slug obrigatório'),
  description: Yup.string().required('Descrição obrigatória'),
});

type Role = {
  id: string;
  slug: string;
  permissions: Pick<Permission, 'id'>[];
  description: string;
  createdAt?: string;
  updateAt?: string;
};

interface RouterParams {
  id?: string | undefined;
}

const RoleForm: React.FC = () => {
  const { register, handleSubmit, errors, reset } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState<Role>();
  const [permissions, setPermissions] = useState([]);
  const { id } = useParams<RouterParams>();

  const onSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        if (id) {
          const response = await api.put<Role>(`roles/${id}`, data);
          setRole(response.data);
        } else {
          await api.post('roles', data);
          reset();
        }
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Salvo com sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [addToast, id],
  );

  useEffect(() => {
    if (id) {
      api.get<Role>(`roles/${id}`).then((response) => {
        const { data } = response;
        setRole(data);

        const roleFormEdit = {
          ...data,
          permissions: data.permissions.map((permission) => permission.id),
        };
        reset(roleFormEdit);
      });
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    const params = {
      page: 1,
      per_page: 1000,
      term: '',
    };

    api.get('permissions', { params }).then((response) => {
      const { data } = response;
      const permissionsWithKeyGroup = data.data.map(
        (permission: Permission) => {
          const group = permission.slug.substring(
            0,
            permission.slug.indexOf('_'),
          );
          return {
            ...permission,
            name: permission.description,
            group,
          };
        },
      );

      const result = groupObjectByKey(permissionsWithKeyGroup, 'group');
      setPermissions(result);
      // Object.entries(result).map((item) => console.log(item));
    });
  }, []);

  return (
    <S.Container>
      <HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <MdKeyboardBackspace size={24} color="#fff" />
            <Link to="/roles">Funcões</Link>
          </S.BackLink>
          <HeaderInfo
            title={id ? `Editando: ${role?.slug}` : 'Nova Função / Papel'}
            icon={FaUserCog}
          />
        </S.HeaderPageDatail>
      </HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>Slug</Label>
            <Input
              type="text"
              register={register}
              errors={errors.slug}
              name="slug"
              placeholder="Slug"
            />
          </FormGroup>

          <FormGroup>
            <Label>Descrição</Label>
            <Input
              type="text"
              register={register}
              errors={errors.description}
              name="description"
              placeholder="Descrição"
            />
          </FormGroup>

          {Object.entries(permissions).map((item) => (
            <div key={item[0]}>
              <S.TitlePermission>{item[0]}</S.TitlePermission>
              <Checkbox
                register={register}
                name="permissions"
                items={Array.from(item[1])}
              />
            </div>
          ))}

          <Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};

export default RoleForm;
